import React from 'react';
import { useState, useCallback,useEffect } from 'react';
import styled from 'styled-components'
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import {useCookies} from 'react-cookie';


const Block = styled.div`
    .customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
     
    }

    .customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    min-width: 80px ;
    }

  

    .customers tr:nth-child(even){background-color: #f2f2f2;}

    .customers tr:hover {background-color: #ddd;}

    .customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: black;
    color: white;
    }

    .customers img{
        text-align: center ;
        height : 300px;
    }

    .customers input {
        width: 89%;
    }

    .customers select{
        width : 90%;
    }
    
        
  

    .btn{
        width:100% ;
        height: 50px;
        background-color: lightgray;
        color: black ;
        font-size: 18px;
        
        
    }

   
`

const polymer = ({site}) => {
    
    const [cookie, setCookie, removeCookie] = useCookies(['auth','name']);
    
    const [dataList, setDataList] = useState([]);
    const [transportVendorList, setTransportVendorList] = useState([]);
    const[refDatas, setRefDatas] = useState(null)
    
    //const size = match.params.location
   
    //const location = data.location
    //console.log(site)

    const [loading, setLoading] = useState(false)
    const [imgUpload, setImgUpload] = useState(false)
    const [form, setForm] = useState ({
        
        orderType : 'zor', //오더유형
        orderPath : '10', //유통경로
        productType : '10', //제품군
        orderToVendor : '', //판매처
        orderReVendor : '', //납품처
        //이부분은 dorderDateText //납품일시
        orderCondition : '표준일반(01)', //출하조건
        orderMnumber : '', //자재번호
        orderAmount : '', //오더수량
        orderPlant : 'h510', //플랜트
        orderBatch : '', //배치번호
        orderCost : '1500000', //금액
        orderCurrency : 'KRW', // 통화,
        refTransVendor : '',


        
    })
    const [startDate, setStartDate] = useState(new Date());
    const [orderDateText, setOrderDateText] = useState(new Date());

    useEffect(() => {
              
     
        fetchGetData();
        

    },[cookie.auth])

    const fetchGetData = async() => {
    
        try{
                    

            const response = await axios.get(
                `${process.env.REACT_APP_HOST_RPA}/rpa/transportvendorlist`,{
                    
                    headers:{
                        'token': cookie.auth,
                        
                    }
                }
            );
            

            //console.log("새로고침 ")


            
            if(response.data.articles.length != 0){
             
                setTransportVendorList(response.data.articles)
                //setRefDatas(response.data.articles)

                //console.log(response.data.articles)
             

                
            }else{
                setTransportVendorList(null)
            }
            
            
            
    
        }catch (e) {
            //console.log(e);
           
        }
        
    
      };

    const onChange = useCallback(e => {
        
    
        const nextForm = {
          ...form,
          [e.target.name]: e.target.value
        };

        
        setForm(nextForm);
       
        
    },[form]);

    const GetVendorList = useCallback((e) => {
        //console.log(e.target.value)

        const nextForm={
            ...form,
            [e.target.name]:e.target.value
        }

        setForm(nextForm)

        
        if(e.target.value.length <=1) return;

        
        
    // 필터링
        const filterData = transportVendorList.filter(refData=> { 
            
            if ( refData.vendorname.includes(e.target.value) ) {
                    
                return true;
            } 
            })

            //console.log(filterData)
            setRefDatas(filterData)
    
        
        
    
    },);

    const addDataList = () => {

        if( form.orderToVendor== ''  || form.orderReVendor == '' || form.orderMnumber == '' || form.orderAmount == '' || form.orderBatch == '' ){
            alert('빈 값이 존재합니다.')
            return;
        }

        if(form.orderMnumber.length != 8){
            alert("자재번호가 올바르지 않습니다.")
            return;
        }

        
        const nextList = [
            ...dataList, form
        ]
          
          setDataList(nextList)

          //console.log(dataList)

    }

    const deleteDataList = (index) =>{
        
        dataList.splice(index,1)

        const nextList = [
            ...dataList
        ]
        
        setDataList(nextList)

        

    }


    const submitData = async() => {
    
        if(dataList.length == 0 ){
            alert("주문할내용이 없습니다")
            return
        }

        if(form.refTransVendor == '' ){
            alert("운송사를 선택해주세요")
            return
        }
    


    if(window.confirm("등록할까요?")){
        setLoading(true)
    

            //console.log(new Date(orderDateText).toISOString().split("T")[0].replace(/-/g,"/"))
            
        try{
            const title = "주문처리 " + form.orderReVendor.split(" ")[0];
            let rpadata =''

            for(var i = 0; i < dataList.length; i++){ 


                if(form.orderBatch == ""){
                    rpadata = rpadata + dataList[i].orderType + " " +
                        dataList[i].orderPath + " " +
                        dataList[i].productType + " " +
                        dataList[i].orderToVendor + " " +
                        dataList[i].orderReVendor + " " +
                        new Date(orderDateText).toISOString().split("T")[0].replace(/-/g,"/") + " " +
                        dataList[i].orderCondition + " " +
                        dataList[i].orderMnumber.trim() + " " +
                        dataList[i].orderAmount.trim() + " " +
                        dataList[i].orderPlant + " " +
                        "공장출하" + " " +
                        dataList[i].orderCost.trim() + " " +
                        dataList[i].orderCurrency + " "


                }else{
                    rpadata = rpadata + dataList[i].orderType + " " +
                        dataList[i].orderPath + " " +
                        dataList[i].productType + " " +
                        dataList[i].orderToVendor + " " +
                        dataList[i].orderReVendor + " " +
                        new Date(orderDateText).toISOString().split("T")[0].replace(/-/g,"/") + " " +
                        dataList[i].orderCondition + " " +
                        dataList[i].orderMnumber.trim() + " " +
                        dataList[i].orderAmount.trim() + " " +
                        dataList[i].orderPlant + " " +
                        dataList[i].orderBatch + " " +
                        dataList[i].orderCost.trim() + " " +
                        dataList[i].orderCurrency + " "


                }
                
            }

            //console.log(rpadata)
            
            

            const enrollData = await axios.post(
            `${process.env.REACT_APP_HOST_RPA}/rpa?title=${title}&rpadata=${rpadata}&refTransVendor=${form.refTransVendor}`,{
                
            },{
                headers:{
                    'token': cookie.auth
                }
                        
                }
            );

            /*
            //여기서 웹소켓 작동 시킴
            const rpaSend = await axios.get(
                `${process.env.REACT_APP_SOCKET}/rpa/rpasocket`,{
                    
                },{
                    headers:{
                        'token': cookie.auth
                    }
                            
                    }
                );

                //console.log(rpaSend.data)

                */
                
    
                //console.log(enrollData.data)
                
                setLoading(false)
                alert("등록되었습니다.")
                
                
                //window.location.reload();
                
            

        }catch{
            setLoading(false)
            //console.log("등록 실패" )
            alert("등록이 실패하였습니다.")

        }
    
    }

    
    
    }
        

    return(
            <>
              
                 <Block>
                 
                 <div>
                   
                    <table className='customers'>
                        
                        <tr>
                                    <td colSpan='2' style={{textAlign:'center'}}>
                                        <span style={{ color: "blue", fontWeight: "bold" }}>[폴리머]</span> 주문하기
                                    
                                    </td>
                                    
                                
                                </tr>
                            
                            
                                <tr>
                                    <td>
                                        오더유형 
                                    </td>
                                    <td>
                                        <select name="orderType" onChange={onChange}>
                                            <option value="zor">ZOR</option>
                                            <option value="zorl">ZORL</option>
                                            <option value="zors">ZORS</option>
                                        </select>
    
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        유통경로
                                    </td>
                                    <td>
                                        <select name="orderPath" onChange={onChange}>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            
                                        </select>
    
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        제품군 
                                    </td>
                                    <td>
                                        <select name="productType" onChange={onChange}>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            
                                        </select>
    
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        판매처 
                                    </td>
                                    <td>
                                    <input type="text" name="orderToVendor" list="transportVendorList1" autoComplete='off' placeholder="판매처를 검색해주세요" onChange={(e)=>GetVendorList(e)} />
                        
                                    <datalist id="transportVendorList1">
                                        {refDatas != null && refDatas.map((item, index) => (
                                        <option value={`${item.vendorname} ${item.vendorcode}`} key={index}>{`${item.vendorname} ${item.vendorcode}`}</option>
                                        ))}
                                    </datalist>  
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        납품처 
                                    </td>
                                    <td>
                                    <input type="text" name="orderReVendor" list="transportVendorList2" autoComplete='off' placeholder="납품처를 검색해주세요" onChange={(e)=>GetVendorList(e)}/>
                        
                                    <datalist id="transportVendorList2">
                                        {refDatas != null && refDatas.map((item, index) => (
                                        <option value={`${item.vendorname} ${item.vendorcode}`} key={index}>{`${item.vendorname} ${item.vendorcode}`}</option>
                                        ))}
                                    </datalist>  
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        운송사 
                                    </td>
                                    <td>
                                        <select name="refTransVendor" onChange={onChange}>
                                            <option value="">운송사를 선택해주세요</option>
                                            <option value="세창종합운수">세창종합운수</option>
                                            <option value="서평">서평</option>
                                            <option value="태양">태양</option>
                                        </select>
    
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        납품일시(도착일)
                                    
                                    </td>
                                    <td>
                                    <DatePicker name='orderDate' selected={orderDateText} onChange={(date) => setOrderDateText(date)} dateFormat="yyyy/MM/dd"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        출하조건 
                                    </td>
                                    <td>
                                        <select name="orderCondition" onChange={onChange}>
                                            <option value="표준일반(01)">표준일반(01)</option>
                                            <option value="광양2창고(77)">광양2창고(77)</option>
                                            <option value="양산2창고(72)">양산2창고(72)</option>
                                            <option value="울산창고(74)">울산창고(74)</option>
                                            
                                        </select>
    
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        자재번호 
                                    </td>
                                    <td>
                                        <input type='text' name='orderMnumber' onChange={onChange}  />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        오더수량 
                                    </td>
                                    <td>
                                        <input type='text' name='orderAmount' onChange={onChange}  />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        플랜트 
                                    </td>
                                    <td>
                                        <select name="orderPlant" onChange={onChange}>
                                            <option value="h510">H510</option>
                                            <option value="h511">H511</option>
                                            <option value="h512">H512</option>
                                            <option value="h520">H520</option>
                                            <option value="h521">H521</option>
                                            
                                        </select>
    
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        배치번호 
                                    </td>
                                    <td>
                                        <input type='text' name='orderBatch' onChange={onChange}  />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        금액 
                                    </td>
                                    <td>
                                        <input type='text' name='orderCost' onChange={onChange} defaultValue='1500000' />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        통화 
                                    </td>
                                    <td>
                                        <select name="orderCurrency" onChange={onChange}>
                                            <option value="KRW">KRW</option>
                                            <option value="USD">USD</option>
                                        
                                            
                                        </select>
    
                                        
                                    </td>
                                </tr>
                                
                                
                            
                                    
                            
                            
                            
                    </table>
    
                    <button className='btn' onClick={() => addDataList()} ><span style={{ color: "blue", fontWeight: "bold" }}>[폴리머]</span>  항목추가</button>
                    
                    
                 </div>
                <table className='customers'>
                    <tr>  
                        <td>
                            - List -
                       
                        </td>
                           
                          
                    </tr>
                 {dataList.length !=0  && <>
                        {dataList.map((data,index) => (<>
                    
                    <tr key={index}> 
                        <td>
                            [ {index} ] - <button onClick={()=> deleteDataList(index)} >삭제</button>
                            <br/>
                            자재번호 : {data.orderMnumber} 
                            <br/>
                            오더수량 : {data.orderAmount}  
                            <br/>
                            배치번호 : {data.orderBatch}
                            <br/>
                            
                        </td>
                        
                        
                    </tr>
                      </>))}
                    </>
                    }
                </table>

                <button className='btn' onClick={() => submitData()} ><span style={{ color: "blue", fontWeight: "bold" }}>[폴리머]</span> 주문등록</button>

                 
                 
                 </Block>

                             
              
            </>
    
        );
    
   
};

export default polymer