import React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import {useCookies} from 'react-cookie';

import styled from 'styled-components'
import axios from 'axios';

import Sms from './Sms'
import Order from './Order'
import MaterialReq from './MaterialReq'
import CarEnroll from './CarEnroll'
import AssignCar from './AssignCar'
import OrderCheck from './OrderCheck'

import WorkList from './WorkList'

import { Card, Col, Row, Button } from 'antd';
import 'antd/dist/reset.css'; // Ant Design 스타일 추가

const Block = styled.div`
    
`

const index = ({match}) => {

  const history = useHistory();
  const [cookie, setCookie, removeCookie] = useCookies(['auth','name']);

  const meta = document.createElement('meta');
    meta.name = "viewport";
    meta.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover";
    document.getElementsByTagName('head')[0].appendChild(meta);


    //console.log(match.params.location)
    //const data = match.params
   
    //const location = data.location
    //console.log(location)
    const [category, setCategory] = useState('ordercheck')
    const [writeOn,setWriteOn] = useState(false)
    const [login, setLogin] = useState(true)
    const [form, setForm] = useState ({})


    const onChange = useCallback(e => {
    
    
      const nextForm = {
        ...form,
        [e.target.name]: e.target.value
      };

      
      setForm(nextForm);
     
      
  },[form]);

  useEffect(() => {
    //console.log(cookies.auth)
    
    fetchLoginCheck();
    //fetchData();

  },[cookie.auth])

  const fetchLoginCheck = async() => {

    try{
      
        const response = await axios.get(
            `${process.env.REACT_APP_HOST_RPA}/rpa/account/test`,{
                headers:{
                    token : cookie.auth
                }
            }
        );
        
            //console.log(response.data)
        
        if(response.data.code == 419 || response.data.code == 401)
        {
            //alert("토큰만료")
            history.push("/login")
        }

        if(cookie.name.split(" ")[0] == "B0001111" ){
          setCategory('order')
        }
        //


    }catch (e) {
        //console.log(e);

        history.push("/login")
       
    }
  }

 

    return(
            <>

              <img src="/header_logo.png" width={"120px"} style={{flaot:'left',margin:'30px',}} />
              
              
              <p/>
              

              <div style={{ paddingLeft: '30px' }}>
             
              
              {cookie.name != null &&<>

                {cookie.name.split(" ")[0] == "B0001111" ? <>
              
                <Button onClick={()=>setCategory('order')}>주문하기</Button> <Button onClick={()=>setCategory('sms')}>SMS발송</Button> <Button onClick={()=>setCategory('materialReq')}>자재조회</Button> <Button onClick={()=>setCategory('assigncar')}>배차처리</Button> <Button onClick={()=>setCategory('carenroll')}>차량등록</Button> <Button onClick={()=>setCategory('ordercheck')}>주문조회</Button>
                
                </> : <>
                <Button onClick={()=>setCategory('ordercheck')}>주문조회</Button> <Button onClick={()=>setCategory('assigncar')}>배차처리</Button> <Button onClick={()=>setCategory('carenroll')}>차량등록</Button> 
                  
                </>}
              
              
              </>}
              
              </div>

              <div style={{ padding: '20px' }}>

                
              <Row gutter={[16, 16]} justify="center">
                <Col xs={24} sm={24} md={12}>
                  <Card title="Category" bordered={true}>
                    
                    {category == 'sms' && <Sms />}
                    {category == 'order' && <Order />}
                    {category == 'materialReq' && <MaterialReq />}
                    {category == 'ordercheck' && <OrderCheck />}
                    {category == 'carenroll' && <CarEnroll />}
                    {category == 'assigncar' && <AssignCar />}
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={12}>
                
                  {<WorkList/>}
                
                </Col>
              </Row>
            </div>
            
            </>
    
        );
    
   
};

export default index