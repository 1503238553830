import React from 'react';
import { useState, useCallback,useEffect } from 'react';
import styled from 'styled-components'
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import {useCookies} from 'react-cookie';
import Polymer from './polymer'
import Monomer from './monomer'
import MoveMaterial from './movematerial'
import { Button } from 'antd';


const Block = styled.div`
    .customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
     
    }

    .customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    min-width: 80px ;
    }

  

    .customers tr:nth-child(even){background-color: #f2f2f2;}

    .customers tr:hover {background-color: #ddd;}

    .customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: black;
    color: white;
    }

    .customers img{
        text-align: center ;
        height : 300px;
    }

    .customers input {
        width: 89%;
    }

    .customers select{
        width : 90%;
    }
    
        
  

    .btn{
        width:100% ;
        height: 50px;
        background-color: lightgray;
        color: black ;
        font-size: 18px;
        
        
    }

   
`

const index = ({site}) => {
    
    const [cookie, setCookie, removeCookie] = useCookies(['auth','name']);
    
    const [dataList, setDataList] = useState([]);
    const [transportVendorList, setTransportVendorList] = useState([]);
    const[refDatas, setRefDatas] = useState(null)
    const [subCategory, setSubCategory] = useState("polymer")
    
    //const size = match.params.location
   
    //const location = data.location
    //console.log(site)

    const [loading, setLoading] = useState(false)
    const [imgUpload, setImgUpload] = useState(false)
    const [form, setForm] = useState ({
        
        orderType : 'zor', //오더유형
        orderPath : '10', //유통경로
        productType : '10', //제품군
        orderToVendor : '', //판매처
        orderReVendor : '', //납품처
        //이부분은 dorderDateText //납품일시
        orderCondition : '표준일반(01)', //출하조건
        orderMnumber : '', //자재번호
        orderAmount : '', //오더수량
        orderPlant : 'h510', //플랜트
        orderBatch : '', //배치번호
        orderCost : '1500000', //금액
        orderCurrency : 'KRW', // 통화,
        refTransVendor : '',

        //여기 재고이동 부분 
        supplyPlantType : 'h510',//공급플랜트
        storeLocation : '3000', //저장위치 



        
    })
    const [startDate, setStartDate] = useState(new Date());
    const [orderDateText, setOrderDateText] = useState(new Date());

    useEffect(() => {
              
     
        fetchGetData();
        

    },[cookie.auth])

    const fetchGetData = async() => {
    
        try{
                    

            const response = await axios.get(
                `${process.env.REACT_APP_HOST_RPA}/rpa/transportvendorlist`,{
                    
                    headers:{
                        'token': cookie.auth,
                        
                    }
                }
            );
            

            //console.log("새로고침 ")


            
            if(response.data.articles.length != 0){
             
                setTransportVendorList(response.data.articles)
                //setRefDatas(response.data.articles)

                //console.log(response.data.articles)
             

                
            }else{
                setTransportVendorList(null)
            }
            
            
            
    
        }catch (e) {
            //console.log(e);
           
        }
        
    
      };


    return(
            <>
              
                 <Block>
                 <div>
                    
                    <button onClick={() => setSubCategory("polymer")} style={{ backgroundColor: "blue", color: "white" }}> 폴리머</button>
                    &nbsp;<button onClick={() => setSubCategory("monomer")} style={{ backgroundColor: "green", color: "white" }}> 모노머</button>
                    &nbsp;<button onClick={() => setSubCategory("move")} style={{ backgroundColor: "purple", color: "white" }}> 재고이동</button>
                    
                    <hr/>
                 </div>
                 <div>
                    {subCategory == "polymer" && <>
                        <Polymer />
                       
                    </>}
                    {subCategory == "monomer" && <>
                        <Monomer/>
                    </>}

                    {subCategory == "move" && <>
                        <MoveMaterial/>
                    </>}
                    
                 </div>
               
                 
                 </Block>

                             
              
            </>
    
        );
    
   
};

export default index